import "./App.css";
import { useEffect, useState } from "react";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import {
  Avatar,
  Box,
  ImageList,
  ImageListItem,
  SvgIcon,
  Typography,
} from "@mui/material";

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Draggable from "react-draggable";
import AlreadyJoinedPopup from "./Components/AlreadyJoinedPopup";
import axios from "axios";
import io from "socket.io-client";
import { successToast } from "./Components/Toaster";
import { Toaster } from "react-hot-toast";

// const queryString = window.location.search;

// // Parse the query string using URLSearchParams
// const urlParams = new URLSearchParams(queryString);

// // Get the Id value
// const user_passcode = urlParams.get("user_passcode");
// const session_name = urlParams.get("session_name");
// Extract URL parameters
const urlParams = new URLSearchParams(window.location.search);

// Get the specific parameters
const user_passcode = urlParams.get("user_passcode");
const session_name = urlParams.get("session_name");

// Log or use these values
console.log("User Passcode: ", user_passcode);
console.log("Session Name: ", session_name);

let socket;
if (user_passcode && session_name) {
  socket = io.connect(`https://api-oops-zoom.oopsstudy.com/`, {
    query: { user_passcode: user_passcode },
  });
}

// const socket = io.connect(`https://api-oops-zoom.testdemo.im/`, {
//   query: { user_passcode: user_passcode },
// });

function App() {
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isAlreadyJoined, setIsAlreadyJoined] = useState(false);
  const [sessionInfoData, setSessionInfoData] = useState();
  const [poupTitle, setPopupTitle] = useState();
  const [poupCode, setPopupCode] = useState();
  const [staticError, setStaticError] = useState();
  const [loader, setloader] = useState(false);
  useEffect(() => {
    getSessionInfo();
    // Check if the socket is connected
    if (socket.connected) {
      console.log("Socket is connected.");

      // Listen to the 'details' event
      // socket.on("host_joined", listener);
      socket.on("host_joined", (event) => {
        console.log("Event Listneed", event);
        if (event.session_name == session_name) {
          setOpenPopup(false);
          const hostJoinedFlag = localStorage.getItem("hostJoinedFlag");
          if (hostJoinedFlag == "true") {
            poupCode == "host_not_joined" && getVideoSDKJWT();
          }
        }
        // listener(event);
      });
    } else {
      console.log("Socket is not connected yet.");

      // Optionally handle the reconnection logic
      socket.on("connect", () => {
        console.log("Socket reconnected.");
        // socket.on("host_joined", listener("jhfsdj"));
        socket.on("host_joined", (event) => {
          console.log("Event Listneed", event);
          if (event.session_name == session_name) {
            setOpenPopup(false);
            console.log(poupCode, "popupcode");
            console.log("in if of socket reconnedted");
            // poupCode == "host_not_joined" &&
            // getVideoSDKJWT();
            const hostJoinedFlag = localStorage.getItem("hostJoinedFlag");

            // Only call getVideoSDKJWT if the flag exists and equals "true"
            if (hostJoinedFlag == "true") {
              getVideoSDKJWT();
            }
          }
          // listener(event);
        });
      });
    }

    // // Cleanup function to remove the event listener
    // return () => {
    //   socket.off("host_joined", listener);
    // };
  }, []);

  // Event listener for going offline
  const handleOffline = () => {
    console.log("User is offline. Page will reload in 15 seconds.");
    setTimeout(() => {
      window.location.reload();
    }, 15000); // 15 seconds delay before reloading
  };

  window.addEventListener("offline", handleOffline);
  const listener = (...args) => {
    console.log("Received details event:", args);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var sessionContainer;
  // var authEndpoint = "https://api-oops-zoom.testdemo.im/v1/zoom/session/jwt";
  // var authEndpoint = "http://192.168.1.23:5000/v1/zoom/session/jwt";
  var authEndpoint = "https://api-oops-zoom.oopsstudy.com/v1/zoom/session/jwt";
  var config = {
    videoSDKJWT: "",
    sessionName: session_name,
    userName: "",
    features: ["video", "audio", "settings", "users", "chat", "share"],
    enforceMultipleVideos: true,
  };
  // var role = 1;

  const getSessionInfo = async () => {
    // setSessionInfoData({
    //   id: "1981",
    //   name: "Wordpress Course",
    //   sub_heading: "Sub title here",
    //   overview: "description here",
    //   course_type: "live",
    //   language: "English",
    //   rating: 0,
    //   rating_total: 0,
    //   instructor_detail: {
    //     author_name: "Rahul Thakor",
    //     user_id: "1",
    //     total_students: "19",
    //     rating: 3.5,
    //     total_courses: 23,
    //     profile_picture:
    //       "http://2.gravatar.com/avatar/e63a24ae115300e5a6d3d3fe76046bdd?s=96&d=mm&r=g",
    //     author_permalink: "http://localhost/tibicle/oops_website/author/admin/",
    //     bio: "",
    //   },
    //   course_content: [
    //     {
    //       id: 78,
    //       title: "Wp Session 1",
    //       description: "",
    //       file_type: "video",
    //       items: [
    //         {
    //           id: 1983,
    //           title: "wp 1",
    //           description: "<p>wp 1 description</p>\n",
    //           duration: "-",
    //           materials_url: "",
    //         },
    //         {
    //           id: 1984,
    //           title: "wp 2",
    //           description: "<p>Wp 2 description</p>\n",
    //           duration: "-",
    //           materials_url: "",
    //         },
    //       ],
    //     },
    //   ],
    //   course_review: {
    //     reviews: [],
    //     paged: 1,
    //     total: 0,
    //     per_page: 10,
    //   },
    // });

    try {
      setloader(true);
      const res = await axios.get(
        // `https://api-oops-zoom.testdemo.im/wp/v2/zoom/course-details?session_name=${session_name}`
        // `https://oops.testdemo.im/wp-json/wp/v2/zoom/course-details?session_name=${session_name}`
        `https://oopsstudy.com/wp-json/wp/v2/zoom/course-details?session_name=${session_name}`
      );
      setSessionInfoData(res?.data?.data);
      setloader(false);
    } catch (error) {
      setloader(false);
      console.log(error, "error");
      setStaticError(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something is wrong with session url"
      );
      handleOpen();
    }
  };

  function getVideoSDKJWT() {
    console.log("get video sdk api called");
    sessionContainer = document.getElementById("sessionContainer");

    document.getElementById("join-flow").style.display = "none";

    // fetch(authEndpoint, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     session_name: session_name,
    //     role: Number(role),
    //     user_identity: user_identity,
    //   }),
    // })
    const storedPasscode = localStorage.getItem("user_passcode");

    // Set is_refresh to true if user_passcode exists in localStorage, otherwise false
    const isRefresh = storedPasscode ? true : false;
    const payload = {
      session_name: session_name,
      user_passcode: user_passcode,
      is_refresh: isRefresh,
    };
    const res = axios
      .post(authEndpoint, payload)
      .then((response) => {
        console.log(response);
        if (response.data.status == "success") {
          config.videoSDKJWT = response.data.data.signature;
          config.userName = response.data.data.user_passcode;
          // if (response.data.data.role == 1) {
          //   const cloudRecording = client.getRecordingClient();
          //   cloudRecording.startCloudRecording();
          // }
          localStorage.setItem("user_passcode", user_passcode);
          setPopupCode(null);
          localStorage.setItem("hostJoinedFlag", "false");
          joinSession();
        } else {
          handleOpen();
        }
      })
      .catch((error) => {
        console.log("catch called");
        console.log(error);
        const errorMessage = error?.response?.data?.message;

        switch (errorMessage) {
          case "already_joined":
            setPopupTitle("User has already joined the session.");
            setPopupCode("already_joined");
            break;

          case "invalid_session":
            setPopupTitle("Invalid Session / Invalid Passcode.");
            setPopupCode("invalid_session");
            break;

          case "invalid_time":
            setPopupTitle("The session time is invalid.");
            setPopupCode("invalid_time");
            break;

          case "host_not_joined":
            setPopupTitle("Please wait while the host start the session!!");
            setPopupCode("host_not_joined");
            localStorage.setItem("hostJoinedFlag", "true");
            break;
          case "join_before_five_minute":
            setPopupTitle(
              `You can join the session at ${formatTime(
                sessionInfoData?.session_start_time
              )}!!`
            );
            setPopupCode("join_before_five_minute");
            break;
          default:
            setPopupTitle(errorMessage);
            break;
        }
        handleOpen();
      });
  }

  function joinSession() {
    console.log(config);

    console.log(sessionContainer);
    uitoolkit.joinSession(sessionContainer, config);

    uitoolkit.onSessionClosed(sessionClosed);
  }

  var sessionClosed = () => {
    console.log("session closed");
    uitoolkit.closeSession(sessionContainer);
    localStorage.clear();
    document.getElementById("join-flow").style.display = "block";
  };

  const handleOpen = () => {
    setOpenPopup(true);
  };
  function formatDate(startDate) {
    const date = new Date(startDate);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  // function formatTime(startTime) {
  //   const time = new Date(`1970-01-01T${startTime}Z`);
  //   let hours = time.getUTCHours();
  //   const period = hours >= 12 ? "PM" : "AM";
  //   hours = hours % 12 || 12;
  //   return `${hours} ${period}`;
  // }
  function formatTime(startTime) {
    const time = new Date(`1970-01-01T${startTime}Z`);
    let hours = time.getUTCHours();
    let minutes = time.getUTCMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour time to 12-hour format
    hours = hours % 12 || 12;

    // Pad minutes to always show two digits
    minutes = String(minutes).padStart(2, "0");

    return `${hours}:${minutes} ${period}`;
  }

  // Example usage:
  const formattedTime = formatTime("19:45:00");
  console.log(formattedTime); // Output: 7:45

  const handleCopyLink = (link) => {
    console.log("in handle copy link function");
    const linkToCopy = link;
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        console.log("in promise resolve");
        // successToast("Copied link successfully !");
      })
      .catch((err) => {
        console.error("Failed to copy the link: ", err);
      });
  };

  // Helper function to calculate time difference
  const timeAgo = (commentDate) => {
    const currentDate = new Date();
    const commentDateObj = new Date(commentDate);

    const diffInSeconds = Math.floor((currentDate - commentDateObj) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else if (diffInDays < 7) {
      return `${diffInDays} days ago`;
    } else {
      return `${diffInWeeks} weeks ago`;
    }
  };

  const reviewResponce = {
    status: "success",
    message: "",
    data: {
      rated: 5,
      items: {
        5: { rated: 5, total: 1, percent: 100, percent_float: 0 },
        4: { rated: 4, total: 0, percent: 0, percent_float: 0 },
        3: { rated: 3, total: 0, percent: 0, percent_float: 0 },
        2: { rated: 2, total: 0, percent: 0, percent_float: 0 },
        1: { rated: 1, total: 0, percent: 0, percent_float: 0 },
      },
      reviews: [
        {
          user_email: "vrathod@tibicle.com",
          profile_url: null,
          display_name: "vrathod",
          comment_id: "883",
          comment_date: "2024-09-10 15:00:56",
          title: "",
          content: "I have greate experience with this course",
          rate: "5",
        },
      ],
      meta: { per_page: 10, page: 1, total: 1 },
      can_review: false,
    },
  };

  return (
    <div className="App">
      <div className="header">
        <svg
          width="107"
          height="40"
          viewBox="0 0 107 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1061_8189)">
            <path
              d="M7.12048 26.8527C4.92167 25.6337 3.18997 23.9248 1.91398 21.726C0.637995 19.5386 0 17.0664 0 14.3207C0 11.5751 0.637995 9.11421 1.91398 6.93819C3.18997 4.76218 4.92167 3.05326 7.12048 1.83424C9.30789 0.615209 11.7118 0 14.3207 0C16.9296 0 19.3677 0.615209 21.5665 1.83424C23.7539 3.05326 25.4856 4.76218 26.7502 6.93819C28.0148 9.11421 28.6528 11.5751 28.6528 14.3207C28.6528 17.0664 28.0148 19.5386 26.7502 21.726C25.4856 23.9248 23.7539 25.6223 21.5665 26.8527C19.3677 28.0718 16.9638 28.687 14.3207 28.687C11.7118 28.687 9.30789 28.0832 7.12048 26.8527ZM19.288 23.4235C20.7462 22.5805 21.8741 21.3614 22.6944 19.7892C23.5147 18.217 23.9248 16.3942 23.9248 14.3207C23.9248 12.2472 23.5147 10.4358 22.6944 8.87496C21.8741 7.31416 20.7348 6.11792 19.288 5.28624C17.8297 4.45457 16.1777 4.03304 14.3207 4.03304C12.4637 4.03304 10.8117 4.45457 9.35346 5.28624C7.89519 6.11792 6.7673 7.31416 5.94702 8.87496C5.12674 10.4358 4.7166 12.2472 4.7166 14.3207C4.7166 16.3942 5.12674 18.217 5.94702 19.7892C6.7673 21.3614 7.90658 22.5691 9.35346 23.4235C10.8117 24.2666 12.4637 24.6995 14.3207 24.6995C16.1777 24.6995 17.8297 24.2666 19.288 23.4235Z"
              fill="white"
            />
            <path
              d="M35.1125 27.3429C33.415 26.3859 32.0821 25.0415 31.1137 23.3098C30.1453 21.5781 29.6554 19.5616 29.6554 17.2717C29.6554 14.9817 30.1567 13.0108 31.1478 11.2563C32.139 9.5132 33.5062 8.15746 35.2265 7.22325C36.9468 6.27765 38.8721 5.81055 41.0026 5.81055C43.133 5.81055 45.047 6.27765 46.7787 7.22325C48.499 8.16885 49.8548 9.5132 50.8573 11.2563C51.8485 13.0108 52.3498 15.0045 52.3498 17.2717C52.3498 19.5388 51.8371 21.5326 50.8118 23.2871C49.7864 25.0415 48.3965 26.3859 46.6306 27.3429C44.8647 28.2885 42.928 28.767 40.7975 28.767C38.6671 28.767 36.7987 28.2885 35.1125 27.3429ZM44.1242 23.925C45.1609 23.3554 46.004 22.5123 46.642 21.3845C47.2914 20.2566 47.6104 18.8781 47.6104 17.2717C47.6104 15.6653 47.3028 14.2868 46.6876 13.1703C46.0724 12.0538 45.2521 11.2107 44.2267 10.6525C43.2014 10.0828 42.0963 9.80941 40.9228 9.80941C39.7494 9.80941 38.6443 10.0942 37.6303 10.6525C36.6164 11.2221 35.8189 12.0538 35.2265 13.1703C34.634 14.2868 34.3378 15.6539 34.3378 17.2717C34.3378 19.6642 34.953 21.5212 36.1721 22.8199C37.3911 24.1301 38.9405 24.7795 40.7975 24.7795C41.9824 24.7795 43.0875 24.4947 44.1242 23.925Z"
              fill="white"
            />
            <path
              d="M62.6716 6.85868C64.016 6.16372 65.5426 5.81055 67.2287 5.81055C69.1655 5.81055 70.9314 6.28904 72.5378 7.24604C74.1327 8.20303 75.3973 9.53598 76.3088 11.2563C77.2202 12.9766 77.6759 14.9589 77.6759 17.1919C77.6759 19.4249 77.2202 21.4186 76.3088 23.1845C75.3973 24.9504 74.1327 26.3175 72.5378 27.2973C70.9314 28.2771 69.1655 28.767 67.2287 28.767C65.5312 28.767 64.0274 28.4252 62.7058 27.7416C61.3842 27.0581 60.3133 26.2036 59.4816 25.1782V38.9863H54.879V6.17512H59.4816V9.44484C60.2677 8.41949 61.3273 7.55364 62.6716 6.85868ZM72.0593 13.2158C71.4213 12.0994 70.5896 11.2563 69.5528 10.6753C68.5161 10.0942 67.411 9.80941 66.2262 9.80941C65.0413 9.80941 63.9704 10.1056 62.9337 10.698C61.8969 11.2905 61.0652 12.1563 60.4272 13.2842C59.7893 14.4121 59.4816 15.745 59.4816 17.2831C59.4816 18.8211 59.8006 20.154 60.4272 21.2933C61.0538 22.4326 61.8969 23.3098 62.9337 23.9023C63.9704 24.4947 65.0641 24.7909 66.2262 24.7909C67.3882 24.7909 68.5161 24.4833 69.5528 23.8795C70.5896 23.2757 71.4213 22.3984 72.0593 21.2364C72.6859 20.0743 73.0049 18.7299 73.0049 17.2033C73.0049 15.6767 72.6859 14.3323 72.0593 13.2158Z"
              fill="white"
            />
            <path
              d="M83.6001 27.8207C82.2102 27.1941 81.1165 26.3283 80.3076 25.2346C79.4987 24.1409 79.0658 22.9332 79.0202 21.5775H83.7824C83.8621 22.5231 84.3178 23.3092 85.1381 23.9358C85.9584 24.5738 86.9838 24.8814 88.2256 24.8814C89.4674 24.8814 90.5155 24.6307 91.2332 24.1295C91.951 23.6282 92.3042 22.9902 92.3042 22.2155C92.3042 21.3838 91.9054 20.7572 91.1079 20.3585C90.3104 19.9597 89.0572 19.5154 87.3369 19.0255C85.6736 18.5698 84.3064 18.1255 83.2583 17.6925C82.2102 17.2596 81.2988 16.5988 80.5354 15.7102C79.7721 14.8216 79.3848 13.6481 79.3848 12.2012C79.3848 11.0164 79.7379 9.93408 80.4329 8.9543C81.1279 7.97452 82.1304 7.19981 83.4406 6.63017C84.7508 6.06054 86.2432 5.78711 87.9407 5.78711C90.4699 5.78711 92.5092 6.4251 94.0587 7.70109C95.6081 8.97708 96.4284 10.7202 96.5423 12.9304H91.9396C91.8599 11.9392 91.4497 11.1417 90.732 10.5493C90.0028 9.95686 89.0231 9.66065 87.7812 9.66065C86.5394 9.66065 85.6394 9.8885 85.0014 10.3442C84.352 10.7999 84.033 11.4037 84.033 12.1557C84.033 12.7481 84.2495 13.2494 84.6824 13.6481C85.1153 14.0469 85.6394 14.3659 86.2546 14.5937C86.8698 14.8216 87.7926 15.1178 89.0003 15.4596C90.618 15.8925 91.9396 16.3254 92.9763 16.7697C94.0131 17.2141 94.9017 17.8634 95.665 18.7293C96.417 19.5951 96.8043 20.7344 96.8385 22.1585C96.8385 23.4231 96.4853 24.551 95.7904 25.5536C95.0954 26.5447 94.1042 27.3308 92.8282 27.8891C91.5522 28.4587 90.0484 28.7321 88.3281 28.7321C86.5508 28.7663 84.99 28.4587 83.6001 27.8207Z"
              fill="white"
            />
            <path
              d="M101.134 27.8443C100.564 27.2746 100.291 26.5797 100.291 25.748C100.291 24.9163 100.575 24.21 101.134 23.6517C101.703 23.0821 102.398 22.8087 103.23 22.8087C104.062 22.8087 104.722 23.0935 105.292 23.6517C105.862 24.2214 106.135 24.9163 106.135 25.748C106.135 26.5797 105.85 27.286 105.292 27.8443C104.722 28.4139 104.039 28.6873 103.23 28.6873C102.421 28.6873 101.703 28.4025 101.134 27.8443ZM105.531 0.353516L105.007 19.9263H101.168L100.644 0.353516H105.531Z"
              fill="white"
            />
            <path
              d="M21.3836 18.5256C21.6286 17.0164 20.6037 15.5943 19.0945 15.3493C17.5853 15.1043 16.1632 16.1292 15.9182 17.6384C15.6733 19.1476 16.6981 20.5697 18.2073 20.8147C19.7166 21.0596 21.1386 20.0348 21.3836 18.5256Z"
              fill="white"
            />
            <path
              d="M42.5861 22.7394C43.7816 22.7394 44.7508 21.7703 44.7508 20.5748C44.7508 19.3793 43.7816 18.4102 42.5861 18.4102C41.3906 18.4102 40.4215 19.3793 40.4215 20.5748C40.4215 21.7703 41.3906 22.7394 42.5861 22.7394Z"
              fill="white"
            />
            <path
              d="M30.7719 40.0003C29.3592 40.0003 27.9009 39.8294 26.4654 39.4079C20.3817 37.6079 17.4651 34.19 16.3714 32.9026C16.2803 32.7887 16.1549 32.652 16.1208 32.6064C15.5625 32.0368 15.5625 31.1254 16.1208 30.5671C16.6904 30.0089 17.6018 30.0089 18.1601 30.5671C18.2854 30.6924 18.3993 30.8292 18.5702 31.0228C19.5158 32.1279 22.0108 35.0787 27.2743 36.6281C33.0732 38.337 39.8747 34.8394 39.943 34.8052C40.6494 34.4407 41.5152 34.7141 41.8912 35.409C42.2558 36.1154 41.9937 36.9812 41.2874 37.3572C41.0367 37.5053 36.2176 40.0003 30.7719 40.0003Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1061_8189">
              <rect width="106.135" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div className="vertical-line-header"></div>
        <div className="header-text">
          {sessionInfoData?.name ? sessionInfoData?.name : ""}
        </div>
      </div>
      <main>
        <div id="join-flow">
          <Box className="container-xl zoom-container">
            <div className="banner-section">
              <div className="container">
                <div className="banner-title">
                  {sessionInfoData?.name ? sessionInfoData?.name : ""}
                </div>
                <div className="banner-description">
                  {sessionInfoData?.sub_heading
                    ? sessionInfoData?.sub_heading
                    : ""}
                </div>
              </div>
            </div>
            <div className="page-main-container">
              <div className="container">
                <div className="zoom-container">
                  <div className="main-left-section">
                    {sessionInfoData &&
                      sessionInfoData?.course_content &&
                      sessionInfoData?.course_content.length > 0 && (
                        <div className="session-section">
                          <div className="session-section-title">
                            Today’s session
                          </div>
                          <div className="session-box">
                            <div className="session-title">
                              {sessionInfoData?.course_content &&
                              sessionInfoData?.course_content.length > 0 &&
                              sessionInfoData?.course_content[0]?.title
                                ? sessionInfoData?.course_content[0]?.title
                                : ""}
                            </div>
                            <ul>
                              {sessionInfoData &&
                                sessionInfoData?.course_content &&
                                sessionInfoData?.course_content?.length > 0 &&
                                sessionInfoData?.course_content[0]?.items &&
                                sessionInfoData?.course_content[0]?.items
                                  ?.length > 0 &&
                                sessionInfoData?.course_content[0]?.items?.map(
                                  (item, index) => {
                                    return (
                                      <li key={item?.id}>
                                        <p>{item?.title}</p>
                                        {item.description && (
                                          <div className="tooltip">
                                            <svg
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g clip-path="url(#clip0_5779_19166)">
                                                <path
                                                  d="M7.9987 14.6673C11.6806 14.6673 14.6654 11.6825 14.6654 8.00065C14.6654 4.31875 11.6806 1.33398 7.9987 1.33398C4.3168 1.33398 1.33203 4.31875 1.33203 8.00065C1.33203 11.6825 4.3168 14.6673 7.9987 14.6673Z"
                                                  stroke="#939393"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M8 10.6673V7.00065M8 5.33398H8.00667"
                                                  stroke="#939393"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_5779_19166">
                                                  <rect
                                                    width="16"
                                                    height="16"
                                                    fill="white"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                            <span className="tooltiptext">
                                              {item.description}
                                            </span>
                                          </div>
                                        )}
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </div>
                        </div>
                      )}
                    <div className="session-section ">
                      <div className="session-section-title">
                        Instructor’s Info
                      </div>
                      <div className="instructor-section session-box md:d-flex">
                        <div className="d-flex align-items-center instructor-profile-section">
                          <div className="instructor-logo">
                            {/* <img src="<?php echo get_template_directory_uri(); ?>/asset/img/instructor-logo.svg"> */}
                            {sessionInfoData?.instructor_detail
                              ?.profile_picture && (
                              <img
                                // crossorigin="anonymous"
                                src={
                                  sessionInfoData?.instructor_detail
                                    ?.profile_picture
                                }
                                alt="Profile"
                              />
                            )}
                          </div>

                          <div className="instructor-details">
                            <div className="instructor-heading">
                              {sessionInfoData?.instructor_detail?.author_name
                                ? sessionInfoData?.instructor_detail
                                    ?.author_name
                                : ""}
                            </div>
                            <div className="d-flex align-items-center instructor-list">
                              <SvgIcon className="icon">
                                <svg
                                  width="24"
                                  height="22"
                                  viewBox="0 0 24 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                    fill="#F9931D"
                                  />
                                </svg>
                              </SvgIcon>
                              <span className="fs-14 fw-500 primary-text-color">
                                {sessionInfoData?.instructor_detail?.rating
                                  ? sessionInfoData?.instructor_detail?.rating
                                  : ""}
                                <span className="fw-400 secondary-text-color">
                                  {" "}
                                  Instructor Ratings
                                </span>{" "}
                              </span>
                            </div>
                            <div className="d-flex align-items-center instructor-list">
                              <SvgIcon className="icon">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z"
                                    stroke="#7950E2"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                  />
                                  <path
                                    d="M14.5703 5.43076C15.2408 5.24184 15.9441 5.1988 16.6326 5.30454C17.3212 5.41029 17.9791 5.66236 18.562 6.04377C19.1449 6.42519 19.6393 6.92709 20.012 7.51568C20.3846 8.10427 20.6268 8.76588 20.7221 9.45594C20.8175 10.146 20.764 10.8485 20.565 11.5161C20.366 12.1837 20.0263 12.8009 19.5687 13.3262C19.1111 13.8514 18.5463 14.2726 17.9123 14.5611C17.2782 14.8497 16.5897 14.9991 15.8931 14.9992"
                                    stroke="#7950E2"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M1.5 18.5059C2.26138 17.4229 3.27215 16.539 4.44698 15.9288C5.62182 15.3186 6.92623 15.0001 8.25008 15C9.57393 14.9999 10.8784 15.3184 12.0532 15.9285C13.2281 16.5386 14.239 17.4225 15.0004 18.5054"
                                    stroke="#7950E2"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15.8945 15C17.2185 14.999 18.5232 15.3171 19.6982 15.9273C20.8732 16.5375 21.8838 17.4218 22.6446 18.5054"
                                    stroke="#7950E2"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </SvgIcon>
                              <span className="fs-14 fw-500 primary-text-color">
                                {sessionInfoData?.instructor_detail
                                  ?.total_students
                                  ? sessionInfoData?.instructor_detail
                                      ?.total_students
                                  : ""}{" "}
                                <span className="fw-400 secondary-text-color">
                                  Students
                                </span>{" "}
                              </span>
                            </div>
                            <div className="d-flex align-items-center instructor-list">
                              <SvgIcon className="icon">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                    fill="#2D2F31"
                                    stroke="#2D2F31"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                  />
                                  <path
                                    d="M15 12L10.5 9V15L15 12Z"
                                    fill="white"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </SvgIcon>
                              <span className="fs-14 fw-500 primary-text-color">
                                {sessionInfoData?.instructor_detail
                                  ?.total_courses
                                  ? sessionInfoData?.instructor_detail
                                      ?.total_courses
                                  : ""}{" "}
                                <span className="fw-400 secondary-text-color">
                                  Courses
                                </span>{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex instructor-btn">
                          <Button
                            variant="outlined"
                            className="copy-icon"
                            onClick={() => {
                              {
                                handleCopyLink(
                                  sessionInfoData?.instructor_detail
                                    ?.author_permalink
                                    ? sessionInfoData?.instructor_detail
                                        ?.author_permalink
                                    : ""
                                );
                                console.log("on click event call log");
                              }
                            }}
                          >
                            <SvgIcon className="icon">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.75 15.75H20.25V3.75H8.25V8.25"
                                  stroke="#2D2F31"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M15.75 8.25H3.75V20.25H15.75V8.25Z"
                                  stroke="#2D2F31"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </SvgIcon>
                            &nbsp; Copy link
                          </Button>
                        </div>
                      </div>
                      <div className="instructor-description fs-14 fw-400 secondary-text-color ">
                        {sessionInfoData?.instructor_detail?.bio
                          ? sessionInfoData?.instructor_detail?.bio
                          : ""}
                      </div>
                    </div>
                    {sessionInfoData?.course_review?.reviews &&
                      sessionInfoData?.course_review?.reviews.length > 0 && (
                        <div className="rating-section">
                          <div className="tab-content-section">
                            <div className="fs-24 fw-600 primary-text-color">
                              Ratings & reviews
                            </div>
                            <div className="user-rating-section d-flex align-items-center justify-content-between">
                              <div className="left-section">
                                <div className="total-star">
                                  {reviewResponce?.data?.rated
                                    ? reviewResponce?.data?.rated
                                    : 0}
                                </div>
                                {/* <div className="d-flex star-img me-1 justify-content-between mb-3">
                                  <SvgIcon>
                                    <svg
                                      width="24"
                                      height="22"
                                      viewBox="0 0 24 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                        fill="#F9931D"
                                      />
                                    </svg>
                                  </SvgIcon>
                                  <SvgIcon>
                                    <svg
                                      width="24"
                                      height="22"
                                      viewBox="0 0 24 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                        fill="#F9931D"
                                      />
                                    </svg>
                                  </SvgIcon>
                                  <SvgIcon>
                                    <svg
                                      width="24"
                                      height="22"
                                      viewBox="0 0 24 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                        fill="#F9931D"
                                      />
                                    </svg>
                                  </SvgIcon>
                                  <SvgIcon>
                                    <svg
                                      width="24"
                                      height="22"
                                      viewBox="0 0 24 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                        fill="#F9931D"
                                      />
                                    </svg>
                                  </SvgIcon>
                                  <SvgIcon>
                                    <svg
                                      width="24"
                                      height="22"
                                      viewBox="0 0 24 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                        fill="#F9931D"
                                      />
                                    </svg>
                                  </SvgIcon>
                                </div> */}
                                <StarRating
                                  rate={
                                    reviewResponce?.data?.rated
                                      ? Number(reviewResponce?.data?.rated)
                                      : 0
                                  }
                                />
                                <div className="total-ratings">
                                  Total{" "}
                                  {
                                    sessionInfoData?.course_review?.reviews
                                      ?.length
                                  }{" "}
                                  Ratings
                                </div>
                              </div>
                              <div className="right-section">
                                <ul>
                                  <li className="ratings-list d-flex align-items-center">
                                    <div className="fs-14 fw-500 d-flex align-items-center me-1">
                                      5
                                      <SvgIcon>
                                        <svg
                                          width="24"
                                          height="22"
                                          viewBox="0 0 24 22"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                            fill="#F9931D"
                                          />
                                        </svg>
                                      </SvgIcon>
                                    </div>
                                    <div className="ratings-grey-border">
                                      <div
                                        className="ratings-border"
                                        style={{
                                          width: `${reviewResponce?.data?.items?.[5]?.percent}%`,
                                        }}
                                      ></div>
                                    </div>
                                  </li>
                                  <li className="ratings-list d-flex align-items-center">
                                    <div className="fs-14 fw-500 d-flex align-items-center me-1">
                                      4
                                      <SvgIcon>
                                        <svg
                                          width="24"
                                          height="22"
                                          viewBox="0 0 24 22"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                            fill="#F9931D"
                                          />
                                        </svg>
                                      </SvgIcon>
                                    </div>
                                    <div className="ratings-grey-border">
                                      <div
                                        className="ratings-border"
                                        style={{
                                          width: `${reviewResponce?.data?.items?.[4]?.percent}%`,
                                        }}
                                      ></div>
                                    </div>
                                  </li>
                                  <li className="ratings-list d-flex align-items-center">
                                    <div className="fs-14 fw-500 d-flex align-items-center me-1">
                                      3
                                      <SvgIcon>
                                        <svg
                                          width="24"
                                          height="22"
                                          viewBox="0 0 24 22"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                            fill="#F9931D"
                                          />
                                        </svg>
                                      </SvgIcon>
                                    </div>
                                    <div className="ratings-grey-border">
                                      <div
                                        className="ratings-border"
                                        style={{
                                          width: `${reviewResponce?.data?.items?.[3]?.percent}%`,
                                        }}
                                      ></div>
                                    </div>
                                  </li>
                                  <li className="ratings-list d-flex align-items-center">
                                    <div className="fs-14 fw-500 d-flex align-items-center me-1">
                                      2
                                      <SvgIcon>
                                        <svg
                                          width="24"
                                          height="22"
                                          viewBox="0 0 24 22"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                            fill="#F9931D"
                                          />
                                        </svg>
                                      </SvgIcon>
                                    </div>
                                    <div className="ratings-grey-border">
                                      <div
                                        className="ratings-border"
                                        style={{
                                          width: `${reviewResponce?.data?.items?.[2]?.percent}%`,
                                        }}
                                      ></div>
                                    </div>
                                  </li>
                                  <li className="ratings-list d-flex align-items-center">
                                    <div className="fs-14 fw-500 d-flex align-items-center me-1">
                                      1
                                      <SvgIcon>
                                        <svg
                                          width="24"
                                          height="22"
                                          viewBox="0 0 24 22"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                            fill="#F9931D"
                                          />
                                        </svg>
                                      </SvgIcon>
                                    </div>
                                    <div className="ratings-grey-border">
                                      <div
                                        className="ratings-border"
                                        style={{
                                          width: `${reviewResponce?.data?.items?.[1]?.percent}%`,
                                        }}
                                      ></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/* <div className="user-rating-section pt-1">
                              <div className="d-flex align-items-center mb-3 pb-1">
                                <div className="user-profile-img me-3">
                                  <Avatar
                                    alt="Remy Sharp"
                                    src="../../assets/image/Instructor-Images.png"
                                  />
                                </div>
                                <div className="ps-2">
                                  <div className="user-name">
                                    Kyle Altenwerth
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex star-img me-1">
                                      <div className="d-flex star-img me-1 justify-content-between mb-3">
                                        <SvgIcon>
                                          <svg
                                            width="24"
                                            height="22"
                                            viewBox="0 0 24 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                              fill="#F9931D"
                                            />
                                          </svg>
                                        </SvgIcon>
                                        <SvgIcon>
                                          <svg
                                            width="24"
                                            height="22"
                                            viewBox="0 0 24 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                              fill="#F9931D"
                                            />
                                          </svg>
                                        </SvgIcon>
                                        <SvgIcon>
                                          <svg
                                            width="24"
                                            height="22"
                                            viewBox="0 0 24 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                              fill="#F9931D"
                                            />
                                          </svg>
                                        </SvgIcon>
                                        <SvgIcon>
                                          <svg
                                            width="24"
                                            height="22"
                                            viewBox="0 0 24 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                              fill="#F9931D"
                                            />
                                          </svg>
                                        </SvgIcon>
                                        <SvgIcon>
                                          <svg
                                            width="24"
                                            height="22"
                                            viewBox="0 0 24 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
                                              fill="#F9931D"
                                            />
                                          </svg>
                                        </SvgIcon>
                                      </div>
                                    </div>
                                    <div className="fs-14 fw-500 secondary-text-color ms-1">
                                      3 weeks ago
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p className="user-message">
                                Was a great className, I started and took some
                                other classNamees and came back to it. Angela
                                covered many of the fundamentals to get you
                                started in Web Development. The Web 3 stuff was
                                super interesting but a large learning
                              </p>
                            </div> */}

                            {sessionInfoData?.course_review?.reviews
                              ?.slice(0, 4)
                              ?.map((data, index) => {
                                return (
                                  <div className="user-rating-section">
                                    <div className="d-flex align-items-center mb-3 pb-1">
                                      <div className="user-profile-img me-3">
                                        <Avatar
                                          alt={data?.display_name}
                                          src={data?.profile_url}
                                        />
                                      </div>
                                      <div className="ps-2">
                                        <div className="user-name">
                                          {data?.display_name
                                            ? data?.display_name
                                            : ""}
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <div className="d-flex star-img me-1">
                                            <StarRating
                                              rate={
                                                data?.rate
                                                  ? Number(data?.rate)
                                                  : 0
                                              }
                                            />
                                          </div>
                                          <div className="fs-14 fw-500 secondary-text-color ms-1">
                                            {data?.comment_date
                                              ? timeAgo(data?.comment_date)
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="user-message">
                                      {data?.content ? data?.content : ""}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="main-right-section">
                    <div className="session-detail-section">
                      <div className="session-detail-title">
                        Session Details
                      </div>
                      <div className="session-detail-list">
                        <div className="session-detail-item ">
                          <span>Instructor :- </span>
                          <p>
                            {sessionInfoData?.instructor_detail?.author_name
                              ? sessionInfoData?.instructor_detail?.author_name
                              : ""}
                          </p>
                        </div>
                        <div className="session-detail-item">
                          <span>Date :- </span>
                          <p>
                            {sessionInfoData?.session_date
                              ? formatDate(sessionInfoData?.session_date)
                              : ""}
                          </p>
                        </div>
                        <div className="session-detail-item">
                          <span>Time :- </span>
                          <p>
                            {sessionInfoData?.session_start_time
                              ? formatTime(sessionInfoData?.session_start_time)
                              : ""}{" "}
                            -{" "}
                            {sessionInfoData?.session_end_time
                              ? formatTime(sessionInfoData?.session_end_time)
                              : ""}
                          </p>
                        </div>
                        <div className="btn-section">
                          <Button
                            variant="contained"
                            onClick={getVideoSDKJWT}
                            className="purple-bg-btn join-btn"
                          >
                            Join in Zoom
                          </Button>
                        </div>
                      </div>
                    </div>
                    {/* <Button onClick={handleOpen}>waiting message modal</Button> */}
                  </div>
                </div>
              </div>
            </div>
          </Box>
          {/* <button onClick={getVideoSDKJWT} className="join-button">
              Join Session
            </button> */}

          {/* <div className="course-content-container">
            <div className="course-content-title">Course Content</div>
            <div className="accordian-container-parent">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="accordian-container"
                >
                  Session 1: Getting Started
                </AccordionSummary>
                <AccordionDetails>
                  <div className="accordian-li">1. What is Webflow?</div>
                  <div className="accordian-li">2. Sign up in Webflow</div>
                  <div className="accordian-li">3. Teaser of Webflow</div>
                  <div className="accordian-li">4. Figma Introduction</div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div> */}
          <div className="footer">
            <footer id="colophon" class="site-footer">
              <section class="footer-section">
                <div class="footer-container w-100">
                  <div class="row justify-content-between footer-container-custom">
                    <div class="col-lg-4 col-md-6 width-33">
                      <div class="details-section">
                        <a href="/">
                          {/* <img src="<?php echo get_template_directory_uri(); ?>/asset/img/foot-logo.svg" alt=""> */}
                        </a>
                        <p class="fw-400">
                          What defines OOPS STUDY is not just the courses we
                          offer but the supportive community we’ve cultivated.
                          Our team of dedicated instructors is passionate about
                          your success, providing guidance and encouragement
                          every step of the way.
                        </p>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 width-15">
                      <div class="links-section d-flex justify-content-center">
                        <ul>
                          <li>
                            <h3>Quick Links</h3>
                            <a href="<?php echo home_url(); ?>" class="fw-400">
                              Home
                            </a>
                            <a
                              href="<?php echo home_url('/about-us'); ?>"
                              class="fw-400"
                            >
                              About Us
                            </a>
                            <a
                              href="<?php echo home_url('/courses'); ?>"
                              class="fw-400"
                            >
                              Courses
                            </a>
                            <a
                              href="<?php echo home_url('/contact-us'); ?>"
                              class="fw-400"
                            >
                              Contact Us
                            </a>
                            <a
                              href="<?php echo home_url('/faq' ); ?>"
                              class="fw-400"
                            >
                              FAQ
                            </a>
                            <a
                              href="<?php echo home_url('/blog' ); ?>"
                              class="fw-400"
                            >
                              Blog
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                      <div class="social-profiles-section d-flex justify-content-center">
                        <ul>
                          <li class="col-lg-12 col-md-6">
                            <h3>Social Profiles</h3>
                            <div class="social-links">
                              <a href="#">
                                <svg
                                  width="52"
                                  height="50"
                                  viewBox="0 0 52 50"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    fill="white"
                                  />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    stroke="white"
                                  />
                                  <g clip-path="url(#clip0_5073_21719)">
                                    <path
                                      d="M38 25C38 18.3726 32.6274 13 26 13C19.3726 13 14 18.3726 14 25C14 30.9895 18.3882 35.954 24.125 36.8542V28.4687H21.0781V25H24.125V22.3562C24.125 19.3488 25.9166 17.6875 28.6576 17.6875C29.9701 17.6875 31.3438 17.9219 31.3438 17.9219V20.875H29.8306C28.34 20.875 27.875 21.8001 27.875 22.75V25H31.2031L30.6711 28.4687H27.875V36.8542C33.6118 35.954 38 30.9895 38 25Z"
                                      fill="#2D2F31"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5073_21719">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(14 13)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </a>
                              <a href="#">
                                <svg
                                  width="52"
                                  height="50"
                                  viewBox="0 0 52 50"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    fill="white"
                                  />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    stroke="white"
                                  />
                                  <g clip-path="url(#clip0_5073_23882)">
                                    <path
                                      d="M21.5502 34.7507C30.6045 34.7507 35.5583 27.2474 35.5583 20.7426C35.5583 20.5317 35.5536 20.316 35.5442 20.1051C36.5079 19.4082 37.3395 18.545 38 17.556C37.1025 17.9553 36.1496 18.2161 35.1739 18.3295C36.2013 17.7136 36.9705 16.7462 37.3391 15.6065C36.3726 16.1793 35.3156 16.5833 34.2134 16.8013C33.4708 16.0123 32.489 15.4898 31.4197 15.3148C30.3504 15.1397 29.2532 15.3218 28.2977 15.8328C27.3423 16.3439 26.5818 17.1554 26.1338 18.142C25.6859 19.1287 25.5754 20.2353 25.8195 21.291C23.8625 21.1928 21.9479 20.6844 20.2 19.7988C18.452 18.9132 16.9097 17.6702 15.673 16.1502C15.0444 17.234 14.8521 18.5164 15.135 19.7368C15.418 20.9573 16.1551 22.0242 17.1964 22.7207C16.4146 22.6959 15.65 22.4854 14.9656 22.1067V22.1676C14.9649 23.3049 15.3581 24.4073 16.0783 25.2875C16.7985 26.1677 17.8013 26.7713 18.9162 26.9957C18.1921 27.1939 17.432 27.2227 16.6948 27.0801C17.0095 28.0582 17.6216 28.9136 18.4458 29.5271C19.27 30.1405 20.2651 30.4813 21.2923 30.502C19.5484 31.8718 17.3942 32.6149 15.1766 32.6113C14.7833 32.6107 14.3904 32.5866 14 32.5392C16.2529 33.9845 18.8735 34.7521 21.5502 34.7507Z"
                                      fill="#2D2F31"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5073_23882">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(14 13)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </a>
                              <a href="#">
                                <svg
                                  width="52"
                                  height="50"
                                  viewBox="0 0 52 50"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    fill="white"
                                  />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    stroke="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.8114 14.3496C18.6278 14.3496 17.4926 14.8197 16.6555 15.6565C15.8184 16.4933 15.3479 17.6283 15.3474 18.8119V30.7159C15.3474 31.8998 15.8177 33.0353 16.6549 33.8724C17.4921 34.7096 18.6275 35.1799 19.8114 35.1799H31.7154C32.8991 35.1794 34.0341 34.7089 34.8708 33.8718C35.7076 33.0347 36.1777 31.8995 36.1777 30.7159V18.8119C36.1773 17.6286 35.707 16.4938 34.8702 15.6571C34.0335 14.8203 32.8988 14.3501 31.7154 14.3496H19.8114ZM32.9994 18.8188C32.9994 19.1597 32.864 19.4868 32.6229 19.7279C32.3817 19.969 32.0547 20.1045 31.7137 20.1045C31.3727 20.1045 31.0457 19.969 30.8046 19.7279C30.5635 19.4868 30.428 19.1597 30.428 18.8188C30.428 18.4778 30.5635 18.1507 30.8046 17.9096C31.0457 17.6685 31.3727 17.533 31.7137 17.533C32.0547 17.533 32.3817 17.6685 32.6229 17.9096C32.864 18.1507 32.9994 18.4778 32.9994 18.8188ZM25.7651 21.1982C24.8195 21.1982 23.9125 21.5739 23.2438 22.2426C22.5751 22.9113 22.1994 23.8182 22.1994 24.7639C22.1994 25.7096 22.5751 26.6165 23.2438 27.2852C23.9125 27.9539 24.8195 28.3296 25.7651 28.3296C26.7108 28.3296 27.6178 27.9539 28.2865 27.2852C28.9552 26.6165 29.3309 25.7096 29.3309 24.7639C29.3309 23.8182 28.9552 22.9113 28.2865 22.2426C27.6178 21.5739 26.7108 21.1982 25.7651 21.1982ZM20.4834 24.7639C20.4834 23.3636 21.0397 22.0206 22.0299 21.0304C23.0201 20.0402 24.3631 19.4839 25.7634 19.4839C27.1638 19.4839 28.5068 20.0402 29.497 21.0304C30.4871 22.0206 31.0434 23.3636 31.0434 24.7639C31.0434 26.1642 30.4871 27.5072 29.497 28.4974C28.5068 29.4876 27.1638 30.0439 25.7634 30.0439C24.3631 30.0439 23.0201 29.4876 22.0299 28.4974C21.0397 27.5072 20.4834 26.1642 20.4834 24.7639Z"
                                    fill="#2D2F31"
                                  />
                                </svg>
                              </a>
                              <a href="#">
                                <svg
                                  width="52"
                                  height="50"
                                  viewBox="0 0 52 50"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    fill="white"
                                  />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    stroke="white"
                                  />
                                  <path
                                    d="M35.3415 14.0352H16.6542C15.759 14.0352 15.0352 14.7419 15.0352 15.6156V34.3801C15.0352 35.2538 15.759 35.9648 16.6542 35.9648H35.3415C36.2367 35.9648 36.9648 35.2538 36.9648 34.3844V15.6156C36.9648 14.7419 36.2367 14.0352 35.3415 14.0352ZM21.5412 32.7225H18.2861V22.2545H21.5412V32.7225ZM19.9137 20.8282C18.8686 20.8282 18.0248 19.9844 18.0248 18.9436C18.0248 17.9028 18.8686 17.0591 19.9137 17.0591C20.9545 17.0591 21.7982 17.9028 21.7982 18.9436C21.7982 19.9802 20.9545 20.8282 19.9137 20.8282ZM33.7225 32.7225H30.4716V27.6341C30.4716 26.422 30.4502 24.8587 28.7798 24.8587C27.0879 24.8587 26.8309 26.1821 26.8309 27.5485V32.7225H23.5843V22.2545H26.7024V23.6851H26.7453C27.1779 22.8627 28.2401 21.9932 29.8206 21.9932C33.1143 21.9932 33.7225 24.1605 33.7225 26.9788V32.7225Z"
                                    fill="#2D2F31"
                                  />
                                </svg>
                              </a>
                              <a href="#">
                                <svg
                                  width="52"
                                  height="50"
                                  viewBox="0 0 52 50"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    fill="white"
                                  />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="51"
                                    height="49"
                                    stroke="white"
                                  />
                                  <path
                                    d="M23.8 28.4286L29.509 25L23.8 21.5714V28.4286ZM36.516 19.48C36.659 20.0171 36.758 20.7371 36.824 21.6514C36.901 22.5657 36.934 23.3543 36.934 24.04L37 25C37 27.5029 36.824 29.3429 36.516 30.52C36.241 31.5486 35.603 32.2114 34.613 32.4971C34.096 32.6457 33.15 32.7486 31.698 32.8171C30.268 32.8971 28.959 32.9314 27.749 32.9314L26 33C21.391 33 18.52 32.8171 17.387 32.4971C16.397 32.2114 15.759 31.5486 15.484 30.52C15.341 29.9829 15.242 29.2629 15.176 28.3486C15.099 27.4343 15.066 26.6457 15.066 25.96L15 25C15 22.4971 15.176 20.6571 15.484 19.48C15.759 18.4514 16.397 17.7886 17.387 17.5029C17.904 17.3543 18.85 17.2514 20.302 17.1829C21.732 17.1029 23.041 17.0686 24.251 17.0686L26 17C30.609 17 33.48 17.1829 34.613 17.5029C35.603 17.7886 36.241 18.4514 36.516 19.48Z"
                                    fill="#2D2F31"
                                  />
                                </svg>
                              </a>
                            </div>
                          </li>
                          {/* <li class="col-lg-12 col-md-6">
                            <h3>Newsletter</h3>
                            <div class="email-address d-flex diplay-flex-custom">
                              <input
                                type="email"
                                placeholder="Email"
                                class="form-control me-3 footer-email"
                                id="exampleInputEmail2"
                                aria-describedby="emailHelp"
                              />
                              <button
                                type="button"
                                class="purple-bg-btn fs-16 fw-400 subscribe-btn d-flex justify-content-center align-items-center"
                              >
                                Subscribe
                              </button>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="copyright-section ">
                <div class="copyright flex-wrap  d-flex justify-content-between copy-rgt">
                  <p class="fs-16 fw-400 text-center">
                    Copyright © 2024 <span>OOPS Study</span>. All rights
                    Reserved.
                  </p>
                  <ul class="text-center">
                    <li>
                      <a href="#" class="fw-400">
                        Help
                      </a>
                      <a href="#" class="fw-400">
                        Terms & Condition
                      </a>
                      <a href="#" class="fw-400">
                        Privacy Policy
                      </a>
                      <a href="#" class="fw-400">
                        Refund Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
            </footer>
          </div>
        </div>
        <div id="sessionContainer" className="session-container"></div>
      </main>
      {open && (
        <DraggableDialog
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          sessionInfoData={sessionInfoData}
        />
      )}
      {openPopup && (
        <AlreadyJoinedPopup
          open={openPopup}
          setOpenPopup={setOpenPopup}
          poupTitle={poupTitle}
          staticError={staticError}
          poupCode={poupCode}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Toaster position="top-right" reverseOrder={false} /> */}
    </div>
  );
}

export default App;

const DraggableDialog = ({
  open,
  handleClickOpen,
  handleClose,
  sessionInfoData,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="dialog-position"
        disableBackdropClick={true}
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        ></DialogTitle>
        <DialogContent>
          <div className="course-content-container">
            <div className="course-content-title">
              {sessionInfoData?.course_name}
            </div>
            <div className="accordian-container-parent">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="accordian-container"
                >
                  {sessionInfoData?.lesson_name}
                </AccordionSummary>
                {/* <AccordionDetails>
                  <div className="accordian-li">1. What is Webflow?</div>
                  <div className="accordian-li">2. Sign up in Webflow</div>
                  <div className="accordian-li">3. Teaser of Webflow</div>
                  <div className="accordian-li">4. Figma Introduction</div>
                </AccordionDetails> */}
              </Accordion>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

// const StarRating = ({ rate }) => {
//   const totalStars = 5; // Maximum number of stars

//   // Helper function to generate stars
//   const renderStars = () => {
//     let stars = [];
//     for (let i = 0; i < totalStars; i++) {
//       stars.push(
//         <SvgIcon key={i}>
//           <svg
//             width="24"
//             height="22"
//             viewBox="0 0 24 22"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
//               fill={i < rate ? "#F9931D" : "#e0e0e0"} // Fill star based on rate
//             />
//           </svg>
//         </SvgIcon>
//       );
//     }
//     return stars;
//   };

//   return (
//     <div className="d-flex star-img me-1 justify-content-between mb-3">
//       {renderStars()}
//     </div>
//   );
// };
const StarRating = ({ rate }) => {
  const totalStars = 5; // Maximum number of stars

  // Full star SVG
  const fullStar = (
    <SvgIcon>
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
          fill="#F9931D"
        />
      </svg>
    </SvgIcon>
  );

  // Half star SVG
  const halfStar = (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0.809018L12.0074 6.98708L12.0635 7.15983H12.2451H18.7411L13.4858 10.9781L13.3388 11.0848L13.3949 11.2576L15.4023 17.4357L10.1469 13.6174L10 13.5106L9.85305 13.6174L4.59768 17.4357L6.60505 11.2576L6.66118 11.0848L6.51423 10.9781L1.25886 7.15983H7.75486H7.9365L7.99262 6.98708L10 0.809018Z"
          stroke="#F9931D"
          strokeWidth="0.5"
        />
        <mask
          id="mask0_5779_19251"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="19"
        >
          <path
            d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"
            fill="#F9931D"
          />
        </mask>
        <g mask="url(#mask0_5779_19251)">
          <rect width="10" height="18.75" fill="#F9931D" />
        </g>
      </svg>
    </SvgIcon>
  );

  // Empty star (grey) SVG
  const emptyStar = (
    <SvgIcon>
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
          fill="#e0e0e0"
        />
      </svg>
    </SvgIcon>
  );

  // Helper function to generate stars
  const renderStars = () => {
    let stars = [];
    for (let i = 0; i < totalStars; i++) {
      if (i < Math.floor(rate)) {
        stars.push(fullStar); // Full star
      } else if (i === Math.floor(rate) && rate % 1 !== 0) {
        stars.push(halfStar); // Half star
      } else {
        stars.push(emptyStar); // Empty star
      }
    }
    return stars;
  };

  return (
    <div className="d-flex star-img me-1 justify-content-between mb-3">
      {renderStars().map((star, index) => (
        <span key={index}>{star}</span>
      ))}
    </div>
  );
};
